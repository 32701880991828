<template>
  <div class="my-3">
    <button
      class="btn btn-link p-0"
      @click="visibleText = !visibleText"
    >
      {{ buttonString }}
    </button>
    <b-collapse :visible="visibleText">
      <div class="pt-3">
        <div
          v-dompurify-html="taskText"
          class="bg-light p-3"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'ReviewerTaskText',

  data() {
    return { visibleText: false };
  },

  computed: {
    taskText() {
      return this.$store.getters['reviewers/getAnswer'].step_text;
    },

    buttonString() {
      return this.visibleText ? 'Скрыть условие' : 'Показать условие';
    },
  },
};
</script>
