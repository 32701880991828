<template>
  <div
    v-if="posts"
    class=""
  >
    <button
      class="btn btn-outline-primary mb-3"
      @click="openModal('new')"
    >
      Добавить запись
    </button>
    <table
      v-if="posts.length"
      class="table me-5"
    >
      <thead>
        <tr>
          <th class="without-line-break">Дата публикации</th>
          <th>Текст записи</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(post, index) in posts"
          :key="index"
        >
          <td>{{ formatDate(post.created_at) }}</td>
          <td
            v-dompurify-html="post.text"
            class="with-line-break"
          />
          <td>
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class="mx-2 text-primary"
                @click.prevent="editPost(index)"
              >
                <font-awesome-icon icon="pen-to-square" />
              </a>
              <a
                href="#"
                class="mx-2 text-danger"
                @click.prevent="deletePost(index)"
              >
                <font-awesome-icon icon="trash" />
              </a>
              <a
                :href="linkToPost(post)"
                target="_blank"
                class="btn btn-outline-primary btn-sm without-line-break ms-2"
              >
                Смотреть в ЛК
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal
      v-model="showModal"
      hide-footer
      size="lg"
      no-enforce-focus
      @hide="resetNewPost"
    >
      <template #modal-title>
        <template v-if="modalMode === 'edit'">
          Запись от {{ formatDate(posts[editablePostIndex].created_at) }}
        </template>
        <template v-else>Новая запись</template>
      </template>
      <div
        class="mb-3"
        @focusin.stop
      >
        <Editor
          id="postText"
          v-model:model-value="postText"
          placeholder="Текст записи"
        />
      </div>
      <button
        class="btn btn-primary w-100 mb-3"
        :class="
          !postText || postText === '<p><br></p>' || postText === '<p></p>'
            ? 'disabled'
            : ''
        "
        @click="modalMode === 'edit' ? changePost() : addNewPost()"
      >
        {{ modalMode === 'edit' ? 'Сохранить изменения' : 'Добавить' }}
      </button>
      <button
        class="btn btn-secondary w-100"
        @click="resetNewPost"
      >
        Отмена
      </button>
    </b-modal>
  </div>
</template>

<script setup>
import { makeToast, formatDate } from '@/custom_functions/custom_functions';
import { apiClient } from '@/custom_functions/api_client';

import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);

const showModal = ref(false);
const postText = ref('');
const posts = ref(null);
const modalMode = ref(null);
const editablePostIndex = ref(null);

async function getPosts() {
  const response = await apiClient.get('/v2/posts', {
    params: { course_id: course.value.id },
  });
  posts.value = response.data.posts;
}

getPosts();

function resetNewPost() {
  showModal.value = false;
  editablePostIndex.value = null;
  postText.value = '';
  modalMode.value = null;
}

function openModal(mode) {
  showModal.value = true;
  modalMode.value = mode;
}

async function addNewPost() {
  const response = await apiClient.post('/v2/posts', {
    post: { text: postText.value, course_id: course.value.id },
  });
  makeToast('Запись сохранена!', 'success');
  posts.value.unshift(response.data.post);
  resetNewPost();
}

function editPost(index) {
  openModal('edit');
  editablePostIndex.value = index;
  postText.value = posts.value[editablePostIndex.value].text;
}

async function changePost() {
  const response = await apiClient.patch(
    `/v2/posts/${posts.value[editablePostIndex.value].id}`,
    { post: { text: postText.value } },
  );
  makeToast('Изменения сохранены!', 'success');
  posts[editablePostIndex.value] = response.data.post;
  resetNewPost();
}

async function deletePost(index) {
  await apiClient.delete(`/v2/posts/${posts.value[index].id}`);
  makeToast('Запись удалена!', 'success');
  posts.value.splice(index, 1);
}

function linkToPost(post) {
  const adminHost = document.location.host;
  const { protocol } = document.location;
  let lkHost;
  if (adminHost === 'lvh.me:8081') lkHost = 'lvh.me:8080';
  else if (adminHost === 'admin2.dev.slurm.io')
    lkHost = 'frontend.dev.slurm.io';
  else lkHost = 'edu.slurm.io';
  return `${protocol}//${lkHost}/courses/${course.value.url}?post=${post.id}`;
}
</script>

<style scoped>
.without-line-break {
  white-space: nowrap;
}
.with-line-break {
  white-space: break-spaces;
}
</style>
