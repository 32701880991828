<template>
  <div>
    <CoursesHeader class="mb-4 px-3" />
    <b-tabs
      v-model="tabValue"
      class="px-3"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.value"
      >
        <template #title>
          <font-awesome-icon :icon="tab.icon" />
          {{ tab.title }}
        </template>
      </b-tab>
    </b-tabs>
    <transition
      name="fade"
      mode="out-in"
    >
      <div :key="tabValue">
        <div
          class="mt-4 mb-2 d-flex justify-content-between align-items-start px-3"
        >
          <p
            v-dompurify-html="tabs[tabValue].description"
            class="me-3"
          />
          <CoursesSearch
            :placeholder="tabs[tabValue].searchInputPlaceholder"
            :disabled="httpProcess"
          />
        </div>
        <component :is="tableComponent" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import ContainersTable from '@/components/courses/ContainersTable';
import CoursesHeader from '@/components/courses/CoursesHeader';
import CoursesSearch from '@/components/courses/CoursesSearch';
import CoursesTable from '@/components/courses/CoursesTable.vue';
import ProductsTable from '@/components/courses/products/ProductsTable';

import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useRouteTabs } from '@/composables/use_route_tabs';

const store = useStore();
const route = useRoute();
const router = useRouter();

const tabs = [
  {
    value: 'products',
    icon: 'graduation-cap',
    title: 'Курсы в каталоге',
    component: ProductsTable,
    description: `Курс – то, что показываем в каталоге.<br/>
      Каждый курс может состоять из одного или нескольких тарифов.`,
    searchInputPlaceholder: 'Поиск курса по названию',
    actionName: 'products/fetchProducts',
  },
  {
    value: 'courses',
    icon: 'list',
    title: 'Все тарифы',
    component: CoursesTable,
    description:
      'Список всех тарифов.<br/>Тарифы без привязки к курсу в каталоге не видны.',
    searchInputPlaceholder: 'Поиск тарифа по названию или ID',
    actionName: 'courses/fetchCourses',
  },
  {
    value: 'containers',
    icon: 'box-open',
    title: 'Комплекты и подписки',
    component: ContainersTable,
    description:
      'Старые добрые комплекты.<br/>Собираем пак из курсов и показываем его в каталоге.',
    searchInputPlaceholder: 'Поиск комплекта по названию или ID',
    actionName: 'containers/fetchContainers',
  },
];

const { tabValue } = useRouteTabs(tabs, 'products');

const tableComponent = computed(() => {
  return tabs[tabValue.value].component;
});

function getTabByValue(value) {
  return tabs.find((tab) => tab.value === value);
}

const isTabNameValid = computed(() => !!getTabByValue(route.query.tab));

init();
store.dispatch('course/fetchProductOwners');

watch(route, () => {
  init();
});

function init() {
  setQuery();
  if (isTabNameValid.value && route.query.page) fetchData();
}

function fetchData() {
  const actionName = getTabByValue(route.query.tab).actionName;
  store.dispatch(actionName, { ...route.query });
}

function setQuery() {
  const query = { ...route.query };

  if (!isTabNameValid.value) query.tab = 'products';
  if (!route.query.page) query.page = 1;

  router.replace({ query });
}

const httpProcess = computed(() => {
  return (
    store.getters['products/getHttpProcess'] ||
    store.getters['courses/getHttpProcess'] ||
    store.getters['containers/getHttpProcess']
  );
});
</script>
