<template>
  <div class="container">
    <div v-if="step && reviewers">
      <Breadcrumbs
        v-if="breadcrumbsItems"
        :items="breadcrumbsItems"
        class="mb-2"
      />
      <h1 class="h2 fw-bold mb-3">
        {{ reviewsTitle }}
      </h1>
      <div class="row">
        <div class="col-6">
          <ReviewerAnswersPagination />
        </div>
      </div>
      <hr />
      <ReviewerAnswersSetting
        :settings="settings"
        class="mb-3"
        :no-attempts="!attempts.length"
      />
      <table
        v-if="attempts.length"
        class="table table-striped"
      >
        <thead>
          <tr>
            <th>Логин студента</th>
            <th>Имя студента</th>
            <th>ID решения</th>
            <th>Дата решения</th>
            <th>Статус решения</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="attempt in attempts"
            :key="attempt.id"
          >
            <td>{{ attempt.student_login }}</td>
            <td>{{ attempt.student_full_name }}</td>

            <td>
              <router-link
                :to="`/reviewers/${$route.params.reviewerId}/steps/${step.id}/answers/${attempt.id}`"
                :class="attempt.correct !== null && 'text-secondary'"
              >
                <u>Решение #{{ attempt.id }}</u>
              </router-link>
            </td>

            <td>
              {{ formatCreatedDate(attempt.created_at) }}
              <template v-if="attempt.correct === null">
                <span
                  v-if="attemptIsExpired(attempt.created_at)"
                  :id="`expired-attempts-${attempt.id}`"
                  class="text-danger me-1"
                >
                  <font-awesome-icon icon="circle-exclamation" />
                  <b-tooltip
                    :target="`expired-attempts-${attempt.id}`"
                    triggers="hover"
                  >
                    Время для проверки истекло
                  </b-tooltip>
                </span>
                <span
                  v-else-if="attemptIsRotting(attempt.created_at)"
                  :id="`rotting-attempts-${attempt.id}`"
                  class="text-warning me-1"
                >
                  <font-awesome-icon icon="circle-exclamation" />
                  <b-tooltip
                    :target="`rotting-attempts-${attempt.id}`"
                    triggers="hover"
                  >
                    Меньше суток до окончания срока проверки
                  </b-tooltip>
                </span>
              </template>
            </td>

            <td :class="attemptStatusClass(attempt)">
              {{ attemptStatusString(attempt) }}
            </td>

            <td>
              <button
                v-show="showReviewChangeButton(attempt)"
                data-testid="reviewer-change-button"
                class="btn btn-outline-primary btn-sm"
                @click="handleReviewerChangeButton(attempt.id)"
              >
                Передать задание
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-else
        class="card p-5"
      >
        <div class="card-body p-5 text-center h3 mb-0 text-muted">
          {{ stubText }}
        </div>
      </div>

      <ReviewerChangeModal
        v-model:show="showReviewerChangeModal"
        :reviewers="reviewers"
        :reviewerId="Number($route.params.reviewerId)"
        :isLoading="isReviewerChangeLoading"
        @changeReviewer="changeReviewer"
        @resetCurrentAttemptId="resetCurrentAttemptId"
      />
    </div>

    <div
      v-else
      class="loader text-center"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import moment from 'moment';
import {
  formatAnswersCount,
  attemptStatusString,
  attemptIsRotting,
  attemptIsExpired,
  makeToast,
} from '@/custom_functions/custom_functions';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ReviewerAnswersPagination from '@/components/reviewers/ReviewerAnswersPagination.vue';
import ReviewerAnswersSetting from '@/components/reviewers/ReviewerAnswersSetting.vue';
import ReviewerChangeModal from '@/components/reviewers/ReviewerChangeModal.vue';

const store = useStore();
const route = useRoute();

const settings = ref({ search: null, sort: 'first-old', filter: 'awaiting' });
const showReviewerChangeModal = ref(false);
const currentAttemptId = ref(null);
const isReviewerChangeLoading = ref(false);

const step = computed(() => store.getters['reviewers/getStep']);
const breadcrumbsItems = computed(() => {
  if (step.value) {
    return [
      {
        title: step.value.course.name,
        link: `/reviewers/${route.params.reviewerId}?course=${step.value.course.url}`,
      },
      {
        title: step.value.unit.name,
        link: `/reviewers/${route.params.reviewerId}?course=${step.value.course.url}&unit=${step.value.unit.id}`,
      },
      {
        title: step.value.lesson.title,
        link: `/reviewers/${route.params.reviewerId}?course=${step.value.course.url}&unit=${step.value.unit.id}`,
      },
      { title: `Шаг ${step.value.index}`, link: '' },
    ];
  }
  return null;
});

const attempts = computed(() => {
  if (step.value) {
    const { filter, sort } = settings.value;
    const search = settings.value.search
      ? settings.value.search.toLowerCase()
      : null;
    return step.value.attempts
      .filter((attempt) => {
        const matchSearch =
          +attempt.id === +search ||
          attempt.student_login.toLowerCase().includes(search) ||
          attempt.student_full_name.toLowerCase().includes(search);
        const matchFilter =
          (filter === 'awaiting' && attempt.correct === null) ||
          (filter === 'revision' && attempt.correct === false) ||
          (filter === 'success' && attempt.correct) ||
          filter === 'all';
        return (search ? matchSearch : true) && matchFilter;
      })
      .sort((a1, a2) =>
        sort === 'first-old'
          ? +new Date(a2.created_at) - +new Date(a1.created_at)
          : +new Date(a1.created_at) - +new Date(a2.created_at),
      );
  }
  return null;
});

const stubText = computed(() => {
  const { search, filter } = settings.value;

  if (search) return 'Совпадений не найдено';

  const messages = {
    awaiting: 'Нет непроверенных решений',
    revision: 'Вы еще не оценили ни одного решения',
    success: 'Вы еще не оценили ни одного решения',
    default: 'Нет решений',
  };

  return messages[filter] || messages.default;
});

const reviewsTitle = computed(() => {
  return `${formatAnswersCount(step.value.attempts.length)}.
            Урок "${step.value.lesson.title}",
            шаг ${step.value.index}`;
});

const reviewers = computed(() => store.getters['reviewers/getReviewers']);

watch(
  () => route,
  () => {
    store.dispatch('reviewers/fetchStep', route.params);
  },
  { deep: true },
);

watch(
  () => step.value,
  (newStep) => {
    if (newStep?.course?.id) {
      store.dispatch('reviewers/fetchReviewers', {
        course_id: newStep.course.id,
      });
    }
  },
);

store.dispatch('reviewers/fetchStep', route.params);

const formatCreatedDate = (date) => {
  return moment(date).locale('ru').utcOffset('+3:00').fromNow();
};

const attemptStatusClass = (attempt) => {
  if (attempt.correct === null) return 'text-primary';
  if (!attempt.correct) return 'text-warning';
  if (attempt.correct) return 'text-success';
  return '';
};

const showReviewChangeButton = (attempt) => {
  return !attempt.correct;
};

const handleReviewerChangeButton = (attemptId) => {
  showReviewerChangeModal.value = true;
  currentAttemptId.value = attemptId;
};

const changeReviewer = async (reviewerId) => {
  isReviewerChangeLoading.value = true;

  await store.dispatch('reviewers/changeReviewer', {
    stepId: step.value.id,
    attemptId: currentAttemptId.value,
    reviewerId,
  });

  await store.dispatch('reviewers/fetchStep', route.params);

  makeToast('Задание успешно передано', 'success');

  showReviewerChangeModal.value = false;
  isReviewerChangeLoading.value = false;
  resetCurrentAttemptId();
};

const resetCurrentAttemptId = () => {
  currentAttemptId.value = null;
};
</script>
