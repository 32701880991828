<template>
  <b-modal
    id="reviewer-change-modal"
    title="Передать задание другому ревьюеру"
    :modelValue="show"
    @update:modelValue="$emit('update:show', $event)"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <CustomSelect
      id="reviewer-change-select"
      v-model:model-value="selectedReviewerId"
      label="Выберите из списка:"
      :options="reviewerOptions"
    />

    <template #modal-footer="{ cancel }">
      <b-button
        variant="secondary"
        class="flex-grow-1"
        @click="cancel()"
      >
        Отмена
      </b-button>

      <b-button
        variant="primary"
        :disabled="!isSelectionValid || isLoading"
        class="flex-grow-1"
        @click="handleOk"
      >
        Передать
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import CustomSelect from '@/components/CustomSelect.vue';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  reviewers: {
    type: Array,
    required: true,
  },
  reviewerId: {
    type: Number,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'update:show',
  'change-reviewer',
  'reset-current-attempt-id',
]);

const selectedReviewerId = ref(null);

watch(
  () => props.show,
  (newValue) => {
    if (newValue) {
      selectedReviewerId.value = null;
    }
  },
);

const reviewerOptions = computed(() => {
  return props.reviewers
    .filter((reviewer) => reviewer.id !== props.reviewerId)
    .map((reviewer) => ({
      value: reviewer.id,
      name: reviewer.user.email,
    }));
});

const isSelectionValid = computed(() => {
  return selectedReviewerId.value !== null;
});

const handleCancel = () => {
  emit('update:show', false);
  emit('reset-current-attempt-id');
};

const handleOk = async () => {
  if (isSelectionValid.value) {
    await emit('change-reviewer', selectedReviewerId.value);
  }
};
</script>
