<template>
  <div
    v-if="
      course &&
      products &&
      productOwners &&
      speakers &&
      tags &&
      participations &&
      users
    "
    class="container"
  >
    <CoursesItemHeader
      courses-item-type="course"
      class="mb-4"
      @save="editCourse"
    />
    <CourseTabs />
    <ConflictModal
      v-if="conflict"
      v-model:model-value="shownConflictModal"
      :my-changes="conflict.my_changes"
      :their-changes="conflict.their_changes"
    />
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script setup>
import ConflictModal from '@/components/conflicts/ConflictModal';
import CourseTabs from '@/components/courses/CourseTabs';
import CoursesItemHeader from '@/components/courses/CoursesItemHeader';

import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { makeToast } from '@/custom_functions/custom_functions';

const store = useStore();
const route = useRoute();

store.dispatch('course/fetchCourse', route.params.id);
const course = computed(() => store.getters['course/getCourse']);
const hasErrors = computed(() => store.getters['course/getHasErrors']);

store.dispatch('products/fetchProducts', { omit_pagination: true });
const products = computed(() => store.getters['products/getProducts']);

store.dispatch('course/fetchProductOwners');
const productOwners = computed(() => store.getters['course/getProductOwners']);

store.dispatch('speakers/fetchSpeakers');
const speakers = computed(() => store.getters['speakers/getSpeakers']);

store.dispatch('tags/fetchTagsSettings');
const tags = computed(() => store.getters['tags/getTags']);

store.dispatch('course/fetchParticipations', { id: route.params.id });
const participations = computed(
  () => store.getters['course/getParticipations'],
);

store.dispatch('users/fetchUsers');
const users = computed(() => store.getters['users/getUsers']);

watch(
  course,
  () => {
    if (hasErrors.value) store.dispatch('course/validateCourse');
  },
  { deep: true },
);

const conflict = computed(() => store.getters['course/getConflict']);

const shownConflictModal = ref(false);

watch(conflict, (value) => {
  if (value) shownConflictModal.value = true;
});

async function editCourse() {
  await store.dispatch('course/editCourse');
  if (!hasErrors.value && !conflict.value)
    makeToast('Курс сохранен', 'success');
}
</script>
