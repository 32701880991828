<template>
  <div>
    <b-button
      variant="outline-secondary"
      class="text-nowrap"
      @click="showModal"
    >
      Конвертировать в {{ newTypeName }}
    </b-button>

    <b-modal
      v-model="isModalVisible"
      title="Подтвердите конвертацию"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p class="my-2">
        Вы уверены что хотите конвертировать курс "{{ course.name }}" в
        {{ newTypeName }}?
      </p>

      <template #modal-footer="{ cancel, ok }">
        <b-button
          variant="secondary"
          class="flex-grow-1"
          @click="cancel()"
        >
          Отмена
        </b-button>

        <b-button
          variant="primary"
          class="flex-grow-1"
          @click="ok()"
        >
          Конвертировать
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { validateCourse } from '@/custom_functions/validation_functions';
import { makeToast } from '../../custom_functions/custom_functions';
import { apiClient } from '@/custom_functions/api_client';

const props = defineProps({
  course: Object,
  newType: String,
  newTypeName: String,
  newDisplayedType: String,
});

const emit = defineEmits(['courseConverted']);

const errors = ref(null);

const isModalVisible = ref(false);

const showModal = () => {
  isModalVisible.value = true;
};

const handleOk = () => {
  convertCourseType();
  isModalVisible.value = false;
};

const handleCancel = () => {
  isModalVisible.value = false;
};

const convertCourseType = async () => {
  let response = await apiClient.post(`v2/courses/${props.course.id}/convert`, {
    new_type: props.newType,
  });

  const changedCourse = { ...response.data.course };
  changedCourse.displayed_type = props.newDisplayedType;
  changedCourse.date_to = null;
  changedCourse.date_from = null;
  changedCourse.zoom_url = null;
  if (props.newType === 'demo') changedCourse.price = 0;

  response = await apiClient.patch(`/v2/courses/${props.course.id}`, {
    course: changedCourse,
  });

  emit('courseConverted', response.data.course);
  errors.value = validateCourse(props.course);

  if (Object.keys(errors.value).length) {
    makeToast(
      `Курс конвертирован в ${props.newTypeName}, но для завершения необходимо корректно заполнить отмеченные поля`,
      'warning',
    );
  } else {
    makeToast(`Курс конвертирован в ${props.newTypeName}`, 'success');
  }
};
</script>
